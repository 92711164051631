export const SUBSCRIBER_LOGIN_REQUEST = "SUBSCRIBER_LOGIN_REQUEST";
export const SUBSCRIBER_LOGIN_SUCCESS = "SUBSCRIBER_LOGIN_SUCCESS";
export const SUBSCRIBER_LOGIN_FAIL = "SUBSCRIBER_LOGIN_FAIL";
export const SUBSCRIBER_LOGOUT = "SUBSCRIBER_LOGOUT";
export const SUBSCRIBER_REGISTER_REQUEST = "SUBSCRIBER_REGISTER_REQUEST";
export const SUBSCRIBER_REGISTER_SUCCESS = "SUBSCRIBER_REGISTER_SUCCESS";
export const SUBSCRIBER_REGISTER_FAIL = "SUBSCRIBER_REGISTER_FAIL";
export const SUBSCRIBER_DETAILS_REQUEST = "SUBSCRIBER_DETAILS_REQUEST";
export const SUBSCRIBER_DETAILS_SUCCESS = "SUBSCRIBER_DETAILS_SUCCESS";
export const SUBSCRIBER_DETAILS_FAIL = "SUBSCRIBER_DETAILS_FAIL";

export const SUBSCRIBER_UPDATE_REQUEST = "SUBSCRIBER_UPDATE_REQUEST";
export const SUBSCRIBER_UPDATE_SUCCESS = "SUBSCRIBER_UPDATE_SUCCESS";
export const SUBSCRIBER_UPDATE_FAIL = "SUBSCRIBER_UPDATE_FAIL";
export const SUBSCRIBER_UPDATE_RESET = "SUBSCRIBER_UPDATE_RESET";

export const BILLINGADDRESS_UPDATE_REQUEST = "BILLINGADDRESS_UPDATE_REQUEST";
export const BILLINGADDRESS_UPDATE_SUCCESS = "BILLINGADDRESS_UPDATE_SUCCESS";
export const BILLINGADDRESS_UPDATE_FAIL = "BILLINGADDRESS_UPDATE_FAIL";
