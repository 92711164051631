import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
} from "../constants/categoryConstants";

export const categoryListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true, categories: [] };
    case CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        categories: action.payload.categories,
        success: true,
        pages: action.payload.pages,
        page: parseInt(action.payload.page),
      };
    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    

    default:
      return state;
  }
};
